import React, { useState, useEffect } from 'react';
import { IonCard, IonCardContent, IonButton, IonContent, IonText, IonToggle, IonItem, IonIcon } from '@ionic/react';
import { motion } from 'framer-motion';
import { repeatOutline, caretForwardOutline, caretBackOutline } from 'ionicons/icons';
import './Flashcard.css';

const Flashcard = ({ data }) => {
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isGerman, setIsGerman] = useState(false);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    setCards(shuffleArray([...data.Suggestions]));
  }, [data.Suggestions]);

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  const nextCard = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const previousCard = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prevIndex) => 
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const toggleLanguage = () => {
    setIsGerman(!isGerman);
    setIsFlipped(false);  // Сбрасываем состояние переворота при смене языка
  };

  if (cards.length === 0) {
    return <IonContent className="ion-padding">Loading...</IonContent>;
  }

  const currentCard = cards[currentCardIndex];

  return (
    <IonContent className="ion-padding">
      <IonItem className="language-toggle">
        <IonToggle 
        labelPlacement="start"
            justify="end"
          checked={isGerman}
          onIonChange={toggleLanguage}
          aria-label="Sprache ändern"
        >RU</IonToggle>
        <p>DE</p>
      </IonItem>

      <div className="flashcard-container">
        <motion.div
          className="flashcard"
          animate={{ rotateY: isFlipped ? 180 : 0 }}
          transition={{ duration: 0.6 }}
        >
          <IonCard color="light" className={`card-face card-front ${isFlipped ? 'hidden' : ''}`}>
            <IonCardContent>
              <IonText>
                <h1>{isGerman ? currentCard.text_t : currentCard.text_m}</h1>
              </IonText>
            </IonCardContent>
          </IonCard>
          <IonCard className={`card-face card-back ${isFlipped ? '' : 'hidden'}`}>
            <IonCardContent>
              <IonText>
                <h2>{isGerman ? currentCard.text_m : currentCard.text_t}</h2>
              </IonText>
            </IonCardContent>
          </IonCard>
        </motion.div>
      </div>
      <div className="button-container">
        <IonButton fill="outline" onClick={previousCard}><IonIcon slot="icon-only" icon={caretBackOutline}></IonIcon></IonButton>
        <IonButton color="warning" shape="round" onClick={flipCard}><IonIcon slot="icon-only" icon={repeatOutline}></IonIcon></IonButton>
        <IonButton fill="outline" onClick={nextCard}><IonIcon slot="icon-only" icon={caretForwardOutline}></IonIcon></IonButton>
      </div>
      <IonText className="ion-text-center">
        <p>Карточка {currentCardIndex + 1} из {cards.length}</p>
      </IonText>
    </IonContent>
  );
};

export default Flashcard;