import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonText
} from '@ionic/react';

const WordForm = ({ word }) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWordForm = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://api.lingvolive.com/Translation/InflectedForms`, {
          params: {
            text: word,
            lang: 32775,
            returnJson: true
          },
          headers: {
            'Authorization': 'Bearer YOUR_API_TOKEN_HERE', // Замените на ваш токен API
          },
        });
        setFormData(response.data[0]); // Берем первый элемент массива
      } catch (error) {
        console.error('Ошибка при получении форм слова:', error);
      } finally {
        setLoading(false);
      }
    };

    if (word) {
      fetchWordForm();
    }
  }, [word]);

  if (loading) {
    return <IonSpinner />;
  }

  if (!formData) {
    return <p>Нет данных для отображения.</p>;
  }

  const { lexem, paradigmJson } = formData;
  const { grammar, groups } = paradigmJson;

  // Разделяем грамматическую информацию
  const grammarInfo = grammar.split(',').map(item => item.trim());

  // Определяем артикль
  const getArticle = () => {
    if (grammarInfo.includes('Maskulinum')) return 'Der ';
    if (grammarInfo.includes('Neutrum')) return 'Das ';
    if (grammarInfo.includes('Femininum')) return 'Die ';
    return '';
  };

  const article = getArticle();

  // Находим форму перфекта для глаголов
  const getPerfectForm = () => {
    if (grammarInfo.includes('Verb')) {
      const perfectGroup = groups.find(group => group.name === 'Indikativ, Perfekt, Aktiv');
      if (perfectGroup && perfectGroup.table.length > 0) {
        const firstPerson = perfectGroup.table[0][0];
        return `${firstPerson.prefix}${firstPerson.value}`;
      }
    }
    return null;
  };

  const perfectForm = getPerfectForm();

  return (
    <IonCard>
        <div className='horisontal-block'>
        <IonCardHeader>
            <IonCardTitle>
            {article}{lexem}
            {perfectForm && <span> | {perfectForm}</span>}
            </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
            <IonRow>
            {grammarInfo.map((info, index) => (
                <IonCol key={index} size="auto">
                <IonText color="secondary"><sub>{info}</sub></IonText>
                </IonCol>
            ))}
            </IonRow>
        </IonCardContent>
        </div>
        <IonAccordionGroup expand="inset">
            <IonAccordion value="first">
                <IonItem slot="header" color="secondary">
                    <IonLabel>Формы слова</IonLabel>
                </IonItem>
                <div slot="content">
                    <IonAccordionGroup expand="inset">
                        {groups.map((group, groupIndex) => (
                            <IonAccordion key={groupIndex}>
                            <IonItem slot="header" color="light">
                                <IonLabel>{group.name || 'Склонение'}</IonLabel>
                            </IonItem>
                            <IonGrid slot="content">
                                {group.table.map((row, rowIndex) => (
                                <IonRow key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                    <IonCol key={cellIndex}>
                                        {cell.prefix && <span>{cell.prefix}</span>}
                                        {cell.value}
                                    </IonCol>
                                    ))}
                                </IonRow>
                                ))}
                            </IonGrid>
                            </IonAccordion>
                        ))}
                    </IonAccordionGroup>
                </div>
            </IonAccordion>
        </IonAccordionGroup>
      
    </IonCard>
  );
};

export default WordForm;