import React from 'react';
import { IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonText } from '@ionic/react';

const AkkuzativRules = () => {
  return (
    <>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Что такое Akkusativ?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Akkusativ используется для обозначения прямого дополнения, то есть объекта действия. Он отвечает на вопросы <strong>"кого?"</strong> или <strong>"что?"</strong> (Wen? Was?).</p>
            <p>Akkusativ используется с определенными глаголами, а также с рядом предлогов.</p>
          </IonCardContent>
        </IonCard>

        <IonCard color="primary">
          <IonCardHeader>
            <IonCardTitle>Артикли в Akkusativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>Мужской (der): <span slot="end"><strong>den</strong> / einen</span></IonItem>
              <IonItem>Средний (das): <span slot="end">das / ein</span></IonItem>
              <IonItem>Женский (die): <span slot="end">die / eine</span></IonItem>
              <IonItem>Множественное число (die): <span slot="end">die / (нет артикля)</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры с существительными в Akkusativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Ich sehe den <IonText color="primary">Mann</IonText>.</strong> <span slot="end">Я вижу мужчину.</span></IonItem>
              <IonItem><strong>Sie kauft eine <IonText color="primary">Zeitung</IonText>.</strong> <span slot="end">Она покупает газету.</span></IonItem>
              <IonItem><strong>Wir hören die <IonText color="primary">Musik</IonText>.</strong> <span slot="end">Мы слушаем музыку.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Akkusativ с предлогами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые предлоги, требующие Akkusativ:</p>
            <IonList>
              <IonItem>durch (через)</IonItem>
              <IonItem>für (для)</IonItem>
              <IonItem>gegen (против)</IonItem>
              <IonItem>ohne (без)</IonItem>
              <IonItem>um (вокруг, о)</IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры с предлогами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Ich gehe durch <IonText color="primary">den Park.</IonText></strong> <span slot="end">Я иду через парк.</span></IonItem>
              <IonItem><strong>Das Geschenk ist für <IonText color="primary">meinen Freund.</IonText></strong> <span slot="end">Подарок для моего друга.</span></IonItem>
              <IonItem><strong>Wir spielen gegen <IonText color="primary">die Mannschaft.</IonText></strong> <span slot="end">Мы играем против команды.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Akkusativ с глаголами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые глаголы, требующие Akkusativ:</p>
            <IonList>
              <IonItem>sehen (видеть)</IonItem>
              <IonItem>hören (слышать)</IonItem>
              <IonItem>kaufen (покупать)</IonItem>
              <IonItem>lieben (любить)</IonItem>
              <IonItem>haben (иметь)</IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры с глаголами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Ich sehe <IonText color="primary">den Film.</IonText></strong> <span slot="end">Я смотрю фильм.</span></IonItem>
              <IonItem><strong>Sie hat <IonText color="primary">einen Hund.</IonText></strong> <span slot="end">У неё есть собака.</span></IonItem>
              <IonItem><strong>Wir kaufen <IonText color="primary">das Buch.</IonText></strong> <span slot="end">Мы покупаем книгу.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Akkusativ для указания направления</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые предлоги могут требовать Akkusativ, если они обозначают движение в определённое место:</p>
            <IonList>
              <IonItem><strong>Ich gehe in <IonText color="primary">die Schule.</IonText></strong> <span slot="end">Я иду в школу.</span></IonItem>
              <IonItem><strong>Er legt das Buch auf <IonText color="primary">den Tisch.</IonText></strong> <span slot="end">Он кладёт книгу на стол.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры вопросов</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Wen siehst du?</strong> <span slot="end">Кого ты видишь?</span></IonItem>
              <IonItem>Ответ: Ich sehe <IonText color="primary">_den Mann.</IonText></IonItem>
              <IonItem><strong>Was kaufst du?</strong> <span slot="end">Что ты покупаешь?</span></IonItem>
              <IonItem>Ответ: Ich kaufe <IonText color="primary">_einen Apfel.</IonText></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </>
  );
};

export default AkkuzativRules;
