import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonIcon,
  IonText,
  IonProgressBar
} from '@ionic/react';
import { codeDownloadOutline } from 'ionicons/icons';
import WordForm from '../components/WordForm';
import ExamplesList from '../components/ExamplesList';


const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [showWordForm, setShowWordForm] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('1049'); // Default to Russian
  const [processedWords, setProcessedWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const fetchWords = useCallback(async (word) => {
    if (!word) {
      setResults([]);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(`https://api.lingvolive.com/Translation/WordListPart`, {
        params: {
          prefix: word,
          srcLang: selectedLanguage,
          dstLang: 32775, // Assuming this is always German
          pageSize: 5,
          startIndex: 0,
        },
        headers: {
          'Authorization': 'Bearer YOUR_API_TOKEN_HERE', // Replace with your API token
        },
      });
      setResults(response.data.items);
    } catch (error) {
      console.error('Error fetching word list:', error);
      setResults([]);
    } finally {
      setLoading(false);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchWords(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, fetchWords]);

  const handleSearch = (event) => {
    setSearchTerm(event.detail.value);
    setSelectedWord(null);
    setShowWordForm(false);
    setShowExamples(false);
  };

  const handleWordSelect = (word) => {
    setSelectedWord(word);
    setResults([]);
    setSearchTerm('');
    
    const wordsToProcess = word.srcLangId === 32775 
      ? word.heading 
      : word.lingvoTranslations;
    
      const processedWords = wordsToProcess
      .split(/[;,]/) // Split by both semicolons and commas
      .map(w => w.trim()) // Trim whitespace
      .filter(w => w.length > 0) // Remove empty strings
      .map(w => w.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) // Remove diacritics
      .filter((w, index, self) => self.indexOf(w) === index); // Remove duplicates
    
    setProcessedWords(processedWords);
  };

  const fetchWordDetails = useCallback(async (word) => {
    setLoading(true);
    try {
      // Here you would typically make an API call to fetch details for the specific word
      // For this example, we'll simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update the current word
      setCurrentWord(word);
      
      // Show the word form and examples
      setShowWordForm(true);
      setShowExamples(true);
    } catch (error) {
      console.error('Error fetching word details:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleWordButtonClick = (word) => {
    fetchWordDetails(word);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className='logo-header'>ZWEI.</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='bg-home'>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          <IonSearchbar
            placeholder="Enter a word to search"
            value={searchTerm}
            onIonInput={handleSearch}
            debounce={300}
          ></IonSearchbar>
          
          {loading && <IonProgressBar type="indeterminate"></IonProgressBar>}
          
          {results.length > 0 && searchTerm && (
            <IonList>
              {results.map((result, index) => (
                <IonItem key={index} onClick={() => handleWordSelect(result)}>
                  {result.heading}
                </IonItem>
              ))}
            </IonList>
          )}
          
          {selectedWord && (
            <IonCard>
              <div className='horisontal-block'>
                <IonCardHeader>
                  <IonText color="secondary">
                    <h2 className='big-text'>{selectedWord.heading}</h2>
                  </IonText>
                </IonCardHeader>
                <IonCardHeader>
                  <h3><i>{selectedWord.lingvoTranslations}</i></h3>
                </IonCardHeader>
              </div>
              <IonCardContent>
                {processedWords.map((word, index) => (
                  <IonButton size="small" fill="outline" key={index} onClick={() => handleWordButtonClick(word)}>
                    {word}
                    <IonIcon slot="end" icon={codeDownloadOutline}></IonIcon>
                  </IonButton>
                ))}
              </IonCardContent>
            </IonCard>
          )}

          {showWordForm && (
            <WordForm 
              word={currentWord}
            />
          )}

          {showExamples && (
            <ExamplesList 
              word={currentWord}
              selectedLanguage={selectedLanguage}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;