import React from 'react';
import { IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem } from '@ionic/react';

const NominativRules = () => {
  return (
    <>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Что такое Nominativ?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Nominativ — это основной падеж в немецком языке, который используется для обозначения подлежащего в предложении. Он отвечает на вопросы <strong>"кто?"</strong> или <strong>"что?"</strong> (Wer? Was?).</p>
            <p>В большинстве случаев Nominativ используется для того, чтобы указать на действующее лицо или предмет в предложении.</p>
          </IonCardContent>
        </IonCard>

        <IonCard color="primary">
          <IonCardHeader>
            <IonCardTitle>Артикли в Nominativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
                <IonItem>
                    <span>Мужской (der):</span> <span slot="end">der / ein</span>
                </IonItem>
                <IonItem>
                    <span>Средний (das):</span><span slot="end">das / ein</span>
                </IonItem>
                <IonItem>
                    <span>Женский (die):</span><span slot="end">die / eine</span>
                </IonItem>
                <IonItem>
                    <span>Множественное число (die):</span> <span slot="end">die / (нет артикля)</span>
                </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры с существительными в Nominativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Der Mann liest ein Buch.</strong> <span slot="end">Мужчина читает книгу.</span></IonItem>
              <IonItem><strong>Die Frau kocht.</strong> <span slot="end">Женщина готовит.</span></IonItem>
              <IonItem><strong>Das Kind spielt im Garten.</strong> <span slot="end">Ребёнок играет в саду.</span></IonItem>
              <IonItem><strong>Die Hunde rennen schnell.</strong> <span slot="end">Собаки быстро бегут.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Nominativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p><strong>1. Подлежащее</strong> (основное действующее лицо или предмет)</p>
            <p>В большинстве случаев Nominativ используется для обозначения действующего лица или предмета, который выполняет действие в предложении.</p>
            <IonList>
              <IonItem><strong>Der Lehrer erklärt die Lektion.</strong> <span slot="end">Учитель объясняет урок.</span></IonItem>
              <IonItem><strong>Die Katze schläft auf dem Sofa.</strong> <span slot="end">Кошка спит на диване.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Глаголы, требующие Nominativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые глаголы, такие как <strong>sein</strong> (быть) и <strong>werden</strong> (становиться), требуют Nominativ как для подлежащего, так и для сказуемого.</p>
            <IonList>
              <IonItem><strong>Ich bin ein Lehrer.</strong> <span slot="end">Я - учитель.</span></IonItem>
              <IonItem><strong>Sie wird eine Ärztin.</strong> <span slot="end">Она становится врачом.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры вопросов</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Wer arbeitet hier?</strong> <span slot="end">Кто здесь работает?</span></IonItem>
              <IonItem>Ответ: Der Mann arbeitet hier.</IonItem>
              <IonItem><strong>Was ist das?</strong> <span slot="end">Что это?</span></IonItem>
              <IonItem>Ответ: Das ist ein Buch.</IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Сравнение с другими падежами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Nominativ отличается от других падежей тем, что он всегда обозначает субъект, который выполняет действие, тогда как другие падежи могут обозначать объект действия (Akkusativ) или адресата (Dativ).</p>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры предложений</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Der Hund läuft.</strong> <span slot="end">Собака бежит.</span></IonItem>
              <IonItem><strong>Das Mädchen liest ein Buch.</strong> <span slot="end">Девочка читает книгу.</span></IonItem>
              <IonItem><strong>Die Blumen sind schön.</strong> <span slot="end">Цветы красивые.</span></IonItem>
              <IonItem><strong>Wir sind Schüler.</strong> <span slot="end">Мы - ученики.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Вывод</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Nominativ — это падеж, который используется для обозначения подлежащего, отвечая на вопросы <strong>"кто?"</strong> или <strong>"что?"</strong>. Этот падеж используется с основными глаголами и всегда определяет, кто или что выполняет действие в предложении.</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
};

export default NominativRules;
