import React, { useState, useEffect } from 'react';
import { IonReorderGroup, IonReorder, IonItem, IonLabel, IonButton, IonContent, IonToast, IonIcon } from '@ionic/react';
import { eyeOffOutline, languageOutline } from 'ionicons/icons';
import dialogsData from '../res/dialogsA1.json';

const DialogGame = () => {
  const [currentDialog, setCurrentDialog] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [currentDialogKey, setCurrentDialogKey] = useState('');
  const [showHints, setShowHints] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);

  useEffect(() => {
    loadRandomDialog();
  }, []);

  useEffect(() => {
    let timer;
    if (showHints) {
      timer = setTimeout(() => {
        setShowHints(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [showHints]);

  const loadRandomDialog = () => {
    const dialogKeys = Object.keys(dialogsData["Hallo, wie geht's?"]);
    const randomDialogKey = dialogKeys[Math.floor(Math.random() * dialogKeys.length)];
    const dialog = dialogsData["Hallo, wie geht's?"][randomDialogKey];
    
    const shuffledDialog = Object.entries(dialog)
      .sort(() => Math.random() - 0.5)
      .map(([key, value]) => ({ id: key, text: value }));
    
    setCurrentDialog(shuffledDialog);
    setCurrentDialogKey(randomDialogKey);
    setIsCorrect(false);
    setShowHints(false);
  };

  const handleReorder = (event) => {
    const newOrder = event.detail.complete(currentDialog);
    setCurrentDialog(newOrder);
    setShowHints(false);
  };

  const checkOrder = () => {
    const correctDialog = dialogsData["Hallo, wie geht's?"][currentDialogKey];
    const correctOrder = Object.keys(correctDialog);
    const isCorrectOrder = currentDialog.every((item, index) => item.id === correctOrder[index]);
    setIsCorrect(isCorrectOrder);
    
    if (isCorrectOrder) {
      setToastMessage('Поздравляем! Диалог закончен!');
    } else {
      setToastMessage('Порядок неправильный, попробуйте еще раз');
    }
    setShowToast(true);
  };

  const showHintsTemporarily = () => {
    setShowHints(true);
  };

  const isItemCorrect = (item, index) => {
    const correctDialog = dialogsData["Hallo, wie geht's?"][currentDialogKey];
    const correctOrder = Object.keys(correctDialog);
    return item.id === correctOrder[index];
  };

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  return (
    <IonContent>
      <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
        {currentDialog.map((item, index) => (
          <IonItem 
            key={item.id} 
            color={showHints && !isItemCorrect(item, index) ? 'danger' : ''}
          >
            <IonLabel>{showTranslation ? item.text.translation : item.text.original}</IonLabel>
            <IonReorder slot="end" />
          </IonItem>
        ))}
      </IonReorderGroup>
      <div className='horisontal-block ion-padding-top'>
        <IonButton fill="outline" shape="round" color="warning" expand="block" onClick={showHintsTemporarily}>
            <IonIcon slot="icon-only" icon={eyeOffOutline}></IonIcon>
        </IonButton>
        <IonButton fill="outline" shape="round" color="secondary" expand="block" onClick={toggleTranslation}>
            <IonIcon slot="icon-only" icon={languageOutline}></IonIcon>
        </IonButton>
        <IonButton color="tertiary" expand="block" onClick={checkOrder}>Проверить</IonButton>
      </div>
      {isCorrect && (
        <IonButton expand="block" onClick={loadRandomDialog}>Следующий диалог</IonButton>
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </IonContent>
  );
};

export default DialogGame;