import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  IonCard,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';

const ExamplesList = ({ word, selectedLanguage }) => {
  const [examples, setExamples] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Сбрасываем примеры при изменении слова
    setExamples([]);
  }, [word]);

  const fetchExamples = async () => {
    if (examples.length > 0) return; // Если примеры уже загружены, не загружаем их снова
    setLoading(true);
    try {
      const response = await axios.get(`https://api.lingvolive.com/Translation/Examples`, {
        params: {
          text: word,
          srcLang: 32775,
          dstLang: selectedLanguage,
          pageSize: 8,
          startIndex: 0
        },
        headers: {
          'Authorization': 'Bearer YOUR_API_TOKEN_HERE', // Замените на ваш токен API
        },
      });
      
      setExamples(response.data.rows.slice(0, 8));
    } catch (error) {
      console.error('Ошибка при получении примеров:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionChange = (e) => {
    if (e.detail.value === 'examples') {
      fetchExamples();
    }
  };

  return (
    <IonCard>
      <IonAccordionGroup expand="inset" onIonChange={handleAccordionChange}>
        <IonAccordion value="examples">
          <IonItem slot="header" color="secondary">
            <IonLabel>Примеры</IonLabel>
          </IonItem>
          <div slot="content">
            {loading ? (
              <IonList>
                {[...Array(3)].map((_, index) => (
                  <IonItem key={index}>
                    <IonLabel>
                      <IonSkeletonText animated style={{ width: '100%' }} />
                      <IonSkeletonText animated style={{ width: '80%' }} />
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            ) : examples.length > 0 ? (
              <IonGrid>
                {examples.map((example, index) => (
                  <IonRow key={index}>
                    <IonCol size="12" sizeMd="6">
                      <IonItem lines="none">
                        <IonLabel class="ion-text-wrap">
                          {example.sourceFragment.text}
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                      <IonItem lines="none">
                        <IonLabel class="ion-text-wrap">
                          {example.targetFragment.text}
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            ) : (
              <IonItem lines="none">
                <IonLabel>Примеры не найдены</IonLabel>
              </IonItem>
            )}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </IonCard>
  );
};

export default ExamplesList;