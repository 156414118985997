import React, { useState, useEffect } from 'react';
import { IonButton, IonCard, IonCardContent, IonText, IonContent, IonGrid, IonRow, IonCol, IonProgressBar } from '@ionic/react';
import { motion, AnimatePresence } from 'framer-motion';

const WordGame = ({ data }) => {
  const [gameStarted, setGameStarted] = useState(false);
  const [currentWord, setCurrentWord] = useState(null);
  const [result, setResult] = useState(null);
  const [score, setScore] = useState(0);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [showButtons, setShowButtons] = useState(true);
  const [mistakes, setMistakes] = useState(0);

  const substantivs = data.filter(item => item.pos === "Substantiv");

  const startGame = () => {
    setGameStarted(true);
    pickRandomWord();
    setResult(null);
    setScore(0);
    setMistakes(0);
    setTotalPlayed(0);
    setShowButtons(true);
  };

  const pickRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * substantivs.length);
    setCurrentWord(substantivs[randomIndex]);
    setShowButtons(true);
    setResult(null);
  };

  const handleGuess = (guess) => {
    const correct = currentWord.genera[0] === guess;
    setResult({
      correct,
      article: currentWord.articles[0]
    });
    setShowButtons(false);
    setTotalPlayed(prev => prev + 1);
    if (correct) {
      setScore(prevScore => prevScore + 1);
    } else {
      setMistakes(prevMistakes => prevMistakes + 1);
    }
  };

  const nextWord = () => {
    pickRandomWord();
  };

  useEffect(() => {
    if (gameStarted) {
      pickRandomWord();
    }
  }, [gameStarted]);

  if (!gameStarted) {
    return (
      <IonContent className="ion-padding">
        <IonButton expand="block" onClick={startGame}>Start Game</IonButton>
      </IonContent>
    );
  }

  const correctPercentage = totalPlayed > 0 ? (score / totalPlayed) * 100 : 0;

  return (
    <IonContent className="ion-padding">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentWord?.sch[0].lemma}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <IonCard color={result?.correct ? 'success' : result?.correct === false ? 'danger' : 'light'}>
            <IonCardContent>
              <IonGrid>
                <IonRow className="ion-align-items-center ion-text-center">
                  <IonCol>
                    <IonText>
                      <h1 className='game-text'>{result ? result.article + " " : '__ '}{currentWord?.sch[0].lemma}</h1>
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </motion.div>
      </AnimatePresence>

      <IonCard>
        <IonCardContent className='bottom-gap game-card'>
          <AnimatePresence>
            {showButtons ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <IonButton size="large" shape="round" color="warning" expand="block" onClick={() => handleGuess('mask.')}>Maskulin</IonButton>
                <IonButton size="large" shape="round" color="warning" expand="block" onClick={() => handleGuess('fem.')}>Feminin</IonButton>
                <IonButton size="large" shape="round" color="warning" expand="block" onClick={() => handleGuess('neutr.')}>Neutrum</IonButton>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <IonButton fill="outline" expand="full" size="large" color="tertiary" shape="round" onClick={nextWord}>Next</IonButton>
              </motion.div>
            )}
          </AnimatePresence>
        </IonCardContent>
      </IonCard>

      <IonText className="ion-text-center">
        <p>Richtig: {score} | Falsch: {mistakes} | Total: {totalPlayed}</p>
        <p>Correct Answers: {correctPercentage.toFixed(1)}%</p>
      </IonText>
      <IonProgressBar value={correctPercentage / 100} color="success"></IonProgressBar>
    </IonContent>
  );
};

export default WordGame;