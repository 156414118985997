import React from 'react';
import { IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonText } from '@ionic/react';
import SegmentCardComponent from './SegmentCardComponent';

const DativRules = () => {
  return (
    <>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Что такое Dativ?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Dativ в немецком языке используется для обозначения косвенного дополнения, то есть того, кому или чему предназначено действие.</p>
            <p>Обычно Dativ используется с предлогами, глаголами, а также для ответа на вопрос <strong>Wem?</strong> || <strong>"кому?"</strong> или <strong>"чему?"</strong>.</p>
          </IonCardContent>
        </IonCard>

        <IonCard color="primary">
          <IonCardHeader>
            <IonCardTitle>Артикли в Dativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>Мужской (der): <span slot="end">dem / einem</span></IonItem>
              <IonItem>Средний (das): <span slot="end">dem / einem</span></IonItem>
              <IonItem>Женский (die): <span slot="end">der / einer</span></IonItem>
              <IonItem>Множественное число (die): <span slot="end">den / (нет артикля)</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры с существительными в Dativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Der Mann gibt <IonText color="danger">dem Kind</IonText> einen Apfel.</strong> <span slot="end">Мужчина даёт ребёнку яблоко.</span></IonItem>
              <IonItem><strong>Ich helfe <IonText color="danger">meiner Mutter.</IonText></strong> <span slot="end">Я помогаю своей маме.</span></IonItem>
              <IonItem><strong>Wir schenken <IonText color="danger">dem Lehrer</IonText> ein Buch.</strong> <span slot="end">Мы дарим учителю книгу.</span></IonItem>
              <IonItem><strong>Die Blumen gefallen <IonText color="danger">der Frau.</IonText></strong> <span slot="end">Цветы нравятся женщине.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Dativ с предлогами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые предлоги, требующие Dativ:</p>
            <IonList>
              <IonItem>mit (с)</IonItem>
              <IonItem>nach (после, в)</IonItem>
              <IonItem>bei (у, рядом с)</IonItem>
              <IonItem>seit (с какого-то времени)</IonItem>
              <IonItem>von (от, с)</IonItem>
              <IonItem>zu (к, на)</IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры использования с предлогами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Ich fahre mit <IonText color="danger">dem Auto.</IonText></strong> <span slot="end">Я еду на машине.</span></IonItem>
              <IonItem><strong>Er kommt nach <IonText color="danger">der Arbeit.</IonText></strong> <span slot="end">Он приходит после работы.</span></IonItem>
              <IonItem><strong>Wir sind bei <IonText color="danger">meinen Großeltern.</IonText></strong> <span slot="end">Мы у моих бабушки и дедушки.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Использование Dativ с глаголами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Некоторые глаголы, требующие Dativ:</p>
            <IonList>
              <IonItem>helfen (помогать)</IonItem>
              <IonItem>danken (благодарить)</IonItem>
              <IonItem>gefallen (нравиться)</IonItem>
              <IonItem>gehören (принадлежать)</IonItem>
              <IonItem>antworten (отвечать)</IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры использования с глаголами</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Ich helfe <IonText color="danger">meinem Freund.</IonText></strong> <span slot="end">Я помогаю своему другу.</span></IonItem>
              <IonItem><strong>Das Buch gehört <IonText color="danger">dem Lehrer.</IonText></strong> <span slot="end">Книга принадлежит учителю.</span></IonItem>
              <IonItem><strong>Sie dankt <IonText color="danger">ihrem Vater.</IonText></strong> <span slot="end">Она благодарит своего отца.</span></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Примеры вопросов с Dativ</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem><strong>Wem gibst du das Buch?</strong> <span slot="end">Кому ты даёшь книгу?</span></IonItem>
              <IonItem><p>Ответ: Ich gebe <IonText color="danger">dem Lehrer</IonText> das Buch.</p></IonItem>
              <IonItem><strong>Mit wem gehst du ins Kino?</strong> <span slot="end">С кем ты идёшь в кино?</span></IonItem>
              <IonItem><p>Ответ: Ich gehe mit <IonText color="danger">meinem Freund</IonText> ins Kino.</p></IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Использование Dativ с предлогами</IonCardTitle>
                <IonText>Предлоги с Dativ: вопросы о местоположении (Wo?), направлении (Wohin?), источнике (Woher?) или способе (Wie?).</IonText>
            </IonCardHeader>
            <SegmentCardComponent />
        </IonCard>
      </IonContent>
    </>
  );
};

export default DativRules;