import { IonCol, IonGrid, IonItemDivider, IonLabel, IonRow, IonText } from '@ionic/react';
import React from 'react';

const NewTableComponent = ({ data }) => {
  if (!data) {
    return <IonText color="danger">Ошибка: Данные отсутствуют или некорректны</IonText>;
  }

  return (
    <IonGrid>
      <IonRow>
        {/* Заголовок таблицы с типами рода */}
        {data.Forms && (
          <>
            <IonCol size="4">
              <IonItemDivider color="medium">
                <IonLabel><strong>Form</strong></IonLabel>
              </IonItemDivider>
            </IonCol>
            {data.Forms.slice(0, -1).map((form, index) => (
              <IonCol key={index} size="2">
                <IonItemDivider color="medium">
                  <IonLabel><strong>{form}</strong></IonLabel>
                </IonItemDivider>
              </IonCol>
            ))}
          </>
        )}
      </IonRow>

      {/* Данные для каждого местоимения */}
      {Object.entries(data).map(([key, values]) => {
        if (key === "Forms") return null; // Пропускаем строку "Forms"

        return (
          <IonRow key={key}>
            <IonCol size="4">
              <IonLabel><sub>{key}</sub></IonLabel>
            </IonCol>
            {values.slice(0, -1).map((value, index) => (
              <IonCol key={index} size="2">
                <IonText color="medium"><sub>{value}</sub></IonText>
              </IonCol>
            ))}
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export default NewTableComponent;
