import React, { useState } from 'react';
import { 
  IonButton, 
  IonButtons, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonContent, 
  IonHeader, 
  IonIcon, 
  IonModal, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup
} from '@ionic/react';
import WordGame from '../components/WordGame';
import Flashcard from '../components/Flashcard';  // Импортируем компонент Flashcard
import A1Level from "../res/a1List.json";
import A2Level from "../res/a2List.json";
import B1Level from "../res/b1List.json";
import FlashcardData from "../res/wordsStack01.json";  // Импортируем данные для карточек
import { play } from 'ionicons/icons';
import DialogGame from '../components/DialogGame';

const Games = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('A1');
  const [gameStarted, setGameStarted] = useState(false);
  const [currentGame, setCurrentGame] = useState(null);

  const levels = {
    A1: A1Level,
    A2: A2Level,
    B1: B1Level
  };

  const handleLevelChange = (e) => {
    setSelectedLevel(e.detail.value);
  };

  const startGame = () => {
    setGameStarted(true);
  };

  const resetGame = () => {
    setGameStarted(false);
    setSelectedLevel('A1');
    setCurrentGame(null);
  };

  const openGame = (game) => {
    setCurrentGame(game);
    setIsOpen(true);
    if (game === 'flashcard') {
      setGameStarted(true);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Interaktive</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          <IonCard>
            <img alt="Silhouette of mountains" src="/img/game-word.jpeg" />
            <IonCardHeader>
              <IonCardTitle>MFN-Spiel</IonCardTitle>
              <IonCardSubtitle>Beweise, dass du ein Wortschöpfer bist</IonCardSubtitle>
            </IonCardHeader>
            <IonToolbar className='ion-padding-end'>
              <IonButton slot="end" onClick={() => openGame('mfn')}>
                PLAY
                <IonIcon slot="end" icon={play}></IonIcon>
              </IonButton>
            </IonToolbar>
          </IonCard>
          <IonCard>
            <img alt="Silhouette of mountains" src="/img/card-game.jpeg" />
            <IonCardHeader>
              <IonCardTitle>CARD-Spiel</IonCardTitle>
              <IonCardSubtitle>Einfache Sätze, zum Lernen und Wiederholen, mit Übersetzung.</IonCardSubtitle>
            </IonCardHeader>
            <IonToolbar className='ion-padding-end'>
              <IonButton slot="end" onClick={() => openGame('flashcard')}>
                PLAY
                <IonIcon slot="end" icon={play}></IonIcon>
              </IonButton>
            </IonToolbar>
          </IonCard>
          <IonCard>
            <img alt="Silhouette of mountains" src="/img/dialog-game.jpeg" />
            <IonCardHeader>
              <IonCardTitle>DIALOG-Spiel</IonCardTitle>
              <IonCardSubtitle>Dialogs, dialogs, dialogs - learn, repeat, practice.</IonCardSubtitle>
            </IonCardHeader>
            <IonToolbar className='ion-padding-end'>
              <IonButton slot="end" onClick={() => openGame('dialoggame')}>
                PLAY
                <IonIcon slot="end" icon={play}></IonIcon>
              </IonButton>
            </IonToolbar>
          </IonCard>
        </div>
        
        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{currentGame === 'mfn' ? 'M.F.N. Game' : 'Card Game'}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => {
                  setIsOpen(false);
                  resetGame();
                }}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {currentGame === 'mfn' && !gameStarted ? (
              <>
                <h2>Wähle dein Niveau:</h2>
                <IonList>
                  <IonRadioGroup value={selectedLevel} onIonChange={handleLevelChange}>
                    <IonItem>
                      <IonLabel>A1 (Anfänger)</IonLabel>
                      <IonRadio slot="start" value="A1" />
                    </IonItem>
                    <IonItem>
                      <IonLabel>A2 (Grundlegende Kenntnisse)</IonLabel>
                      <IonRadio slot="start" value="A2" />
                    </IonItem>
                    <IonItem>
                      <IonLabel>B1 (Fortgeschrittene)</IonLabel>
                      <IonRadio slot="start" value="B1" />
                    </IonItem>
                  </IonRadioGroup>
                </IonList>
                <IonButton expand="block" onClick={startGame}>Spiel starten</IonButton>
              </>
            ) : currentGame === 'mfn' && gameStarted ? (
              <>
                <p>Wie gut kennst du das Geschlecht von Wörtern im Deutschen? Testen Sie sich selbst:</p>
                <WordGame data={levels[selectedLevel]} />
              </>
            ) : currentGame === 'flashcard' ? (
              <Flashcard data={FlashcardData} />
            ): currentGame === 'dialoggame' ? (
              <DialogGame />
            ) : null}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Games;