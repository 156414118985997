import React, { useState, useEffect } from 'react';
import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { keyOutline, warningOutline } from 'ionicons/icons';

const Profile = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('1049'); // Default to Russian

  useEffect(() => {
    // Load the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const handleLanguageChange = (event) => {
    const newLanguage = event.detail.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className='profile ion-padding'>
          <IonSelect 
            label="Select translation language" 
            labelPlacement="floating" 
            fill="outline"
            value={selectedLanguage}
            onIonChange={handleLanguageChange}
          >
            <IonSelectOption value="1058">Ukrainian</IonSelectOption>
            <IonSelectOption value="1033">English</IonSelectOption>
            <IonSelectOption value="1049">Russian</IonSelectOption>
          </IonSelect>
          <br />
          <br />
          <br />
          <br />
          <IonButton id="open-toast" color="tertiary" fill="outline">
            <IonIcon slot="start" icon={keyOutline}></IonIcon>Login
          </IonButton>
          <IonToast 
            swipeGesture="vertical" 
            trigger="open-toast" 
            message="Access still denied!" 
            duration={3000} 
            icon={warningOutline}
          ></IonToast>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;